@import 'variables';
body {
  .user-management-module-section {
    //user detail list
    .user-detail {
      .section-1 {
        height: 160px;
        position: relative;
        .profile-pic{
          width: 110px;
          height: 110px;
          margin: 20px 0px 0px 34px;
          background-size: cover;
          border-radius: 50%;
          transition: .3s ;
          position: relative;
          .edit-profile-pic-avatar{
            display:none;
            position:relative;
            top:10px;
            right: 40px;
          }
          .edit-profile-pic {
            display:none;
            position:absolute;
            top:43px;
            left: 40px
          }
          &:hover {
            transform: scale(1.1);
          }
          &:hover .edit-profile-pic{
            display: block;

          }


        }


        .list-basic-data {
          position: absolute;
          top: 1px;
          left: 160px;
          top: 15px;
          .list-item{
            font-size: 12px;
            font-weight: 100;
          }
          .user-name{
            position: absolute;
            font-size: 14px;
          }
        }
        .btn-user-detail{
          float:right;
        }
        .resume{
          margin-top: 37px;
        }
      }
      .resumeNew {
        position: relative;
        right: 6rem;
        top: 2rem;
        float: right;
      }
      .resumeOld {
        position: relative;
        right: -3rem;
        top: 4rem;
        float: right;
      }
      .section-2{
        margin-right: 20px;
        .section-2-list {
          margin: 20px 0px 20px 0px;
        }
      }
    }
    .buttons-cta{
      float:right;
      margin-bottom: 30px;
    }
    .cta-mouse{
      cursor: pointer;
    }



  }
}
