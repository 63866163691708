@import 'variables';
body{
  .auto-organiser-section{
    .heading{
      margin-bottom: 5px;
      h3{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
      }
    }
    .publish-org-data{
      text-align: right;
      margin-bottom: 15px;
      p{
        @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #FF8C00);
      }
    }
    .auto-organiser-batch{
      padding: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      p{
        @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: rgba(0,0,0,.7), $margin:0 0 4px 0);
      }
      h5{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #000000);
      }
    }
    .shared-content-list{
      ul{
        li{
          margin-right: 20px;
          margin-bottom: 20px;
          .folder-card{
            background: #ffffff;
            box-shadow: 0 2px 2px rgba(0,0,0,.25);
            width: 170px;
            border-radius: 4px;
            position: relative;
            .folder-icon{
              height: 120px;
              background: #F0F0F0;
              position: relative;
              border-top-right-radius: 4px;
              border-top-left-radius: 4px;
              background-position: center;
              background-repeat: no-repeat;
              .check-box{
                position: absolute;
                top: 0px;
                left: 0px;
              }
              .folder-img{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
              }
            }
            .folder-info{
              padding: 10px;
              background: #ffffff;
              border-bottom-right-radius: 4px;
              border-bottom-left-radius: 4px;
              position: relative;
              h5{
                @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
                margin-bottom: 3px;
                cursor: pointer;
                &:hover{
                  text-decoration: underline;
                }
              }
              p{
                @include elementStyles($size: 11px, $fontFamily: $ibmplexRegular, $color: rgba(0,0,0,.70));
              }
              .shared-info{
                display: inline-block;
                margin-left: 5px;
                position: absolute;
                right: 15px;
                top: 10px;
                cursor: pointer;
                img{
                  width: 15px;
                }
              }
            }
            .context-menu-action{
              position: absolute;
              right: 10px;
              bottom: 0px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
