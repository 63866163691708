@import 'variables';
body{
  .users-section{
    .users-header{
      h3{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
      }
      margin-bottom: 15px;
      ul.user-bulk-ops{
        float: right;
        >li{
          margin-left: 15px;
          &.search-fields{
            width:250px;
            position: relative;
            span.user-search-close{
              position: absolute;
              right: 5px;
              top: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .user-card{
      background: #F1F1F1;
      padding: 15px;
      height: 160px;
      border-radius: 4px;
      box-shadow: 0 1px 1px rgba(0,0,0,.25);
      position: relative;
      &:hover{
        ul{
          display: inline-block;
        }
      }
      ul{
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
        li{
          margin-left: 6px;
          cursor: pointer;
        }
      }
      .user-pic{
        display: inline-block;
        margin: 0 auto;
        margin-bottom: 10px;
        position: relative;
        .account-status{
          position: absolute;
          bottom: -3px;
          left: -3px;
        }
      }
      .user-name-dsi{
        margin-bottom: 15px;
        h3{
          @include elementStyles($size: 15px, $fontFamily: $ibmplexRegular, $color: #000000, $margin:0 0 4px 0);
        }
        p{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
        }
      }
      .user-other-info{
        h6{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000, $margin:0 0 3px 0);
        }
        p{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: rgba(0,0,0,.7));
        }
      }
    }

  }
  .roles-section{
    .heading{
      margin-bottom: 15px;
      h3{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
      }
    }
  }
  .records-templates{
    .heading{
      margin-bottom: 15px;
      h3{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
      }
      span.notes{
        @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #856404, $padding:5px);
        background: #fff3cd;
        border:1px solid #ffeeba;
        border-radius: 3px;
      }
    }
    .template-card{
      padding: 15px;
      position: relative;
      height: 80px;
      h4{
        img{
          margin-right: 6px;
        }
      }
      ul{
        position: absolute;
        right: 10px;
        top: 10px;
        li{
          margin-left: 15px;
          cursor: pointer;
        }
      }
      p{
        position: absolute;
        bottom: 15px;
        right: 15px;
        left: 15px;
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
        padding: 4px;
        border-radius: 4px;
        font-size: 13px;
        a{
          float: right;
          text-decoration: underline;
          cursor: pointer;
          font-family: $ibmplexMedium;
          color: #856404;
        }
      }
    }

  }
  .edit-user-role{
    h2{
      border-bottom: 1px solid #d7d7d7;
      padding: 10px;
    }
    .user-info{
      background: #f2f2f2;
      padding: 10px;
      span{
        font-size: 13px;
      }
    }
    .user-role{
      padding: 10px;
      margin-top: 10px;
    }
    ul{
      li{
        margin-right: 10px;
      }
    }
  }
}
