@import 'variables';
body{
  .no-margin-tab{
    .MuiTabs-scroller{
      margin-bottom: 0 !important;
    }
    
  }
   .sdk-HeaderView-header{
    #undo{
        display:none !important
    }
   }
  .ck-balloon-panel{z-index:9999 !important} 
  .records-dataroom-modal{
    input[type="text"], textarea, input[type="number"]{
        height: auto !important;
    }
    .MuiInputBase-multiline{
        padding: 5px !important;
    }
    .MuiFormControl-marginNormal{
        margin-bottom: 0 !important;
    }
    .MuiOutlinedInput-input{
        padding: 14px !important;
        @include elementStyles($size: 15px, $color: $blackColor, $fontFamily: $ibmplexRegular, $weight:400)        
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
        padding: 0 !important;
    }
    .MuiTextField-root {
        label{
            @include elementStyles($size: 14px, $color: $blackColor, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .MuiDialogTitle-root{
        padding: 10px 24px !important;
        h2{
            @include elementStyles($size: 16px, $color: $blackColor, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .MuiDialogContent-root{
        padding: 10px 24px;
    }
    .MuiDialogActions-root{
        padding: 10px 24px;
    }
    .drag-drop-cnt{
        min-height: 120px !important;
    }
    .Mui-disabled{
      cursor: not-allowed;
    }
    .notification-type-radio{
      font-size: 14px;
      margin-top: 12px;
      font-weight: 500;
      color: #000000;
      font-family: $ibmplexMedium;
  }
  .records-alerts-mdl{
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
      padding-right: 65px !important;
    }
  }
  }
  .mui-table-format{
    &.hovered-table{
      tr{
        background: #E6EBFB;
        cursor: pointer;
      }
    }
    .actions-cell{
      text-align: right;
      svg{
        cursor: pointer;
      }
    }
    thead{
      background: #B5BEDB;
      th{
        padding: 5px 10px;
        background: #B5BEDB;
        @include elementStyles($size: $font13, $color:rgb(0,0,0), $fontFamily: $ibmplexMedium, $weight:500);
        span{
          @include elementStyles($size: $font13, $color:rgb(0,0,0), $fontFamily: $ibmplexMedium, $weight:500);
          &.MuiTableSortLabel-root{
            &:hover{
              color: #000;
              .MuiTableSortLabel-icon{
                color: #000 !important;
              }
            }
            .MuiTableSortLabel-icon{
              opacity: 0.5 !important;
            }
          }
          &.MuiTableSortLabel-active{
            color: #496ac5;
            .MuiTableSortLabel-icon{
              color: #496ac5 !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
    tbody{
      tr{
        &:hover{
          background: #eff0f5 !important;
        }
        &:nth-child(odd){
          background: #fff;
        }
        &:nth-child(even){
          background: #E6EBFB;
        }
        &.Mui-selected{
          background: rgba(245, 0, 87, 0.08);
        }
        td{
          word-break: break-word;
          padding: 5px 10px;
          @include elementStyles($size: 14px, $color:#000, $fontFamily: $ibmplexRegular, $weight:400);
        }
      }
    }
  }
  .MuiMenu-list, .MuiList-root{
    li{
      @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $color: #000000);
    }
  }
  .MuiPopover-paper{
    min-width: 200px;
  }
  .MuiTabs-scroller{
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 20px;
  }
  .MuiTabs-scrollable{
    margin-bottom: 15px !important;
  }
  .MuiInputLabel-shrink{
    // transform: translate(0, 1.5px) scale(0.90)
  }
  .MuiTextField-root{
    .MuiInputLabel-shrink{
      // transform: translate(0, 1.5px) scale(0.90)
    }
  }
  .MuiTooltip-tooltip{
    font-size: 12px;
  }
  .MuiButton-containedPrimary{
    font-size: 13px;
  }
  @media only screen and (max-width: 667px){
    .MuiDialog-paper{
      margin: 8px !important;
    }
    .MuiTabs-scrollButtons{
      width: 20px !important;
    }
    .MuiDialog-paperWidthSm{
      min-height: 300px !important;
    }
    .mui-table-format{
      tbody{
        tr{
          td{
            font-size: 13px;
            white-space: nowrap;
            ul{
              &.mobile-cta{
                width: 75px;
                display: inline-block;
                li{
                  float: right!important;
                  svg{
                    font-size: 20px;
                  }
                }
              }
            }
            &.file-info{

            }
          }
        }
      }
    }
  }
  @media only screen and (orientation: landscape){
    .MuiDialog-paper{
      margin: 8px !important;
      // min-height: 320px !important;
    }
  }
  .filter-ui-drawer{
    input[type="text"], textarea, input[type="number"]{
      height: auto !important;
    }
    .filter-ui-drawer-header, .filter-ui-drawer-body, .filter-ui-drawer-footer{
        padding: 10px 15px;
    }
    .filter-ui-drawer-header{
        border-bottom: 1px solid #d7d7d7;
        h4{
            @include elementStyles($size: $font16, $color:black, $fontFamily: $ibmplexMedium, $weight:500);
        }
    }
    .filter-ui-drawer-body{
        overflow: auto;
    }
    .filter-ui-drawer-footer{
        border-top: 1px solid #d7d7d7;
    }
  }
  .filter-label{
      .MuiInputLabel-shrink{
        transform:  translate(15px, -4px)scale(0.75);
      }
  }
}
.desktop-header{
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  padding: 0px  !important;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child{
  padding: 10px 14px  !important;
}
}
.home-filter{
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child{
  padding: 1px 4px  !important;
}
}